exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-search-people-js": () => import("./../../../src/pages/search-people.js" /* webpackChunkName: "component---src-pages-search-people-js" */),
  "component---src-pages-spotlight-js": () => import("./../../../src/pages/spotlight.js" /* webpackChunkName: "component---src-pages-spotlight-js" */),
  "component---src-templates-department-js": () => import("./../../../src/templates/department.js" /* webpackChunkName: "component---src-templates-department-js" */),
  "component---src-templates-employee-js": () => import("./../../../src/templates/employee.js" /* webpackChunkName: "component---src-templates-employee-js" */),
  "component---src-templates-eventtag-js": () => import("./../../../src/templates/eventtag.js" /* webpackChunkName: "component---src-templates-eventtag-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-newsevent-js": () => import("./../../../src/templates/newsevent.js" /* webpackChunkName: "component---src-templates-newsevent-js" */),
  "component---src-templates-spotlight-js": () => import("./../../../src/templates/spotlight.js" /* webpackChunkName: "component---src-templates-spotlight-js" */),
  "component---src-templates-spotlighttype-js": () => import("./../../../src/templates/spotlighttype.js" /* webpackChunkName: "component---src-templates-spotlighttype-js" */),
  "component---src-templates-standard-js": () => import("./../../../src/templates/standard.js" /* webpackChunkName: "component---src-templates-standard-js" */),
  "component---src-templates-text-js": () => import("./../../../src/templates/text.js" /* webpackChunkName: "component---src-templates-text-js" */)
}

